var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('remitente'),"rules":{
      required: true,
      max: _vm.senderMaxLength,
    },"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{ref:"sendercombobox",staticClass:"sender-input",attrs:{"id":"sender","items":_vm.senders,"label":_vm.$t('Remitente'),"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":"","maxlength":_vm.senderMaxLength,"counter":_vm.senderMaxLength,"error-messages":errors},on:{"change":function($event){return _vm.formatSender(_vm.sender)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item-content',{attrs:{"id":item.text}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-subtitle',[_c('small',[_vm._v(_vm._s(item.type))])])],1)]}}],null,true),model:{value:(_vm.sender),callback:function ($$v) {_vm.sender=$$v},expression:"sender"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }