export class CurrentRcs {
  encoding
  maxCharacters
  characters
  chunks
  maxCharactersInfo
  hasCustomFields

  constructor (encoding, characters, chunks, maxCharactersInfo, hasCustomFields = false) {
    this.encoding = encoding
    this.maxCharacters = 160
    this.characters = characters
    this.chunks = 1
    this.maxCharactersInfo = maxCharactersInfo
    this.hasCustomFields = false
  }

  static makeFromSmsParserResult (encoding, text) {
    const currentSms = new CurrentRcs(encoding, 0, 1, 0)
    currentSms.maxCharacters = 160
    currentSms.characters = text.length
    currentSms.chunks = 1
    currentSms.maxCharactersInfo = 0
    currentSms.hasCustomFields = false
    return currentSms
  }
}
