import ShippingMode from '@/components/ShippingMode/ShippingMode.vue'
import CertifiedSmsModal from '../CertifiedSmsModal/CertifiedSmsModal.vue'
import VueTagsInput from '@johmun/vue-tags-input'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'AdvancedOptions',
  components: {
    ShippingMode,
    CertifiedSmsModal,
    VueTagsInput,
    HelpText,
  },
  props: ['campaign', 'options', 'twoWayDedicatedSenders', 'preview'],
  data: function () {
    return {
      tag: '',
    }
  },
  methods: {
    setCertifiedSms () {
      this.$emit('changeCertifiedSms')
      if (this.campaign.sendings[0].channel.certified === true) {
        EventBus.$emit('showModalCertifiedSms')
      } else {
        this.campaign.sendings[0].channel.certified = 0
        this.campaign.sendings[0].channel.certified2way = 0
      }
    },

    setCertified2Way (element) {
      if (element.checked === true) {
        this.campaign.sendings[0].channel.certified2way = 1
      } else {
        this.campaign.sendings[0].channel.certified2way = 0
      }
    },

    setFlashSms (element) {
      this.campaign.sendings[0].channel.flash = this.campaign.sendings[0].channel.flash ? 1 : 0
    },

    confirmCertifiedSms () {
      this.campaign.sendings[0].channel.certified = 1
      EventBus.$emit('hideModalCertifiedSms')
      this.$emit('changeCertifiedSms')
    },

    cancelCertifiedSms () {
      this.campaign.sendings[0].channel.certified = false
      this.$emit('changeCertifiedSms')
    },

    updateTags (newTags) {
      this.campaign.sendings[0].setUiTags(newTags)
    },

  },
  computed: {
    isTwoWayDedicatedSender: function () {
      const exists = this.twoWayDedicatedSenders.indexOf(this.campaign.sendings[0].channel.sender) >= 0
      if (!exists) {
        this.campaign.sendings[0].channel.certified2way = 0
      }
      return exists
    },
  },
}
