import { ChannelType } from '@/models/ChannelType'
import FormConfigurationService from '@/services/form-configuration.service.js'
import { Country } from '@/util/Country'
import EventBus from '@/util/EventBus'

export default {
    name: 'Senders',
    props: {
      campaign: Object,
      componentsData: Object,
      senderIn: [Object, String],
      disableComboBox: Boolean,
      justVerified: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: function () {
      return {
        sender: this.senderIn,
        search: '',
        formConfigurationFields: {},
        disableComboBoxIn: this.disableComboBox ? this.disableComboBox : false,
        Country: Country,
        senderMaxLength: 40,
      }
    },
    computed: {
      senders: function () {
        const senderList = []
        const that = this

        if (this.formConfigurationFields.verifiedSenders) {
          this.formConfigurationFields.verifiedSenders.map(function (item, index) {
            senderList.push({
              type: that.$t('Verificado').toString(),
              text: item,
              value: item,
            })
          })
        }

        return senderList
      },
      campaignSender () {
        return this.campaign?.sendings[0].channel.sender
      },
      campaignGroups () {
        return this.campaign?.groups
      },
    },
    methods: {
      formatSender (value) {
        this.sender = value

        if (!this.sender) {
          this.sender = ''
        }

        let tempSender = (typeof this.sender === 'object') ? this.sender.text : this.sender
        if (tempSender.length >= this.calculateMaxLength) {
          tempSender = tempSender.substring(0, this.calculateMaxLength)
        }
        this.sender = tempSender
        if (this.campaign) {
          this.campaign.sendings[0].channel.sender = this.sender
        }
        this.$emit('updated-sender', this.sender)
        EventBus.$emit('validate-campaign-sender')
      },
    },
    watch: {
      campaignSender (newSender) {
        if (this.campaign) {
          this.sender = newSender
        }
      },
    },
    created () {
      if (this.campaign.sendings[0].channel.type === ChannelType.RCS_ID) {
        FormConfigurationService.getRcsFields().then((data) => {
          this.formConfigurationFields = data.formConfigurationFields
          this.campaign.sendings[0].channel.verifiedSenders = data.formConfigurationFields.verifiedSenders
          this.formConfigurationFields.verifiedSenders = data.formConfigurationFields.verifiedSenders.map((verifiedSender) => {
            return verifiedSender.sender
          })
        })
      } else {
        FormConfigurationService.getFields().then((data) => {
          this.formConfigurationFields = data.formConfigurationFields
        })
      }
    },
}
